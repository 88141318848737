"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const SwapSettingButton = ({ idx, itemsCount, className = '', onClick, highlighted, roundBorder, children, }) => {
    const classes = `relative flex-1 py-4 px-1 text-white/50 bg-[#1B1B1E]`;
    const roundBorderClass = (() => {
        if (roundBorder === 'left')
            return 'v2-border-gradient-left';
        if (roundBorder === 'right')
            return 'v2-border-gradient-right';
    })();
    const borderClassName = (0, react_1.useMemo)(() => {
        if (idx > 0 && idx < itemsCount)
            return 'border-l border-black/10 border-white/5';
    }, [idx, itemsCount]);
    return ((0, jsx_runtime_1.jsx)("button", { type: "button", className: `${highlighted ? `v2-border-gradient ${roundBorderClass} bg-v2-gradient bg-transparent` : ''} ${borderClassName} ${classes} ${className} relative`, onClick: onClick, children: (0, jsx_runtime_1.jsx)("div", { className: `h-full w-full leading-none flex justify-center items-center`, children: children }) }));
};
exports.default = SwapSettingButton;
